<script>
import i18n from "@/plugins/i18n";

const propName = "value", eventName = "input"
export default {
  name: "InputDate",
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    savedValue: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    maxValue: {
      type: String,
      default: "",
    },
    minValue: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    fieldId: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    yearOnly: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
    maxLength: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    dialog: false,
    activePicker: null,
    requiredRule: (v) => !!v || i18n.t("pages.application.rules.required"),
  }),
  computed: {
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        if (this.multiple && Array.isArray(payload) && payload.length > this.maxLength)
          return
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
        if (!this.multiple || this.yearOnly)
          this.activePicker = 'YEAR'
      }
    },
    rules() {
      let rulesArray = []
      if (this.required) rulesArray.push(this.requiredRule)
      return rulesArray
    },
    event() {
      return this.yearOnly ? "click:year" : null
    }
  },
  watch: {
    dialog (val) {
      if (!val) return
      if (!this.multiple || this.yearOnly)
        setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None") {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  },
  methods: {
    saveYear(year) {
      this.activePicker = 'YEAR'
      this.dialog = false
      this.model = String(year)
    },
    onChange() {
      if (!this.multiple) this.dialog = false
    }
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    transition="scale-transition"
    width="290"
  >
    <template #activator="{on, attrs}">
      <v-text-field
        v-model="model"
        :label="label"
        outlined
        readonly
        :clearable="!required"
        :rules="rules"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      :no-title="multiple"
      v-model="model"
      :min="minValue"
      :max="maxValue"
      :active-picker.sync="activePicker"
      :multiple="multiple"
      @[event]="saveYear"
      @change="onChange"
    >
      <v-btn
        v-if="multiple"
        text
        block
        @click="dialog = false"
      >
        Закрыть
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>